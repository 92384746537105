import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import {AppBar, Toolbar, Divider, IconButton, Menu, List, 
        MenuItem, ListItemIcon,  ListItemText, Grid, Drawer, Collapse, Typography, TextField, CircularProgress,
        Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,  InputAdornment} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
////
import InputVerification from "./FileInputVerificationComponent"
import ParameterVerification from "./ParameterInputVerification"
import Markets from "./MarketsComponent"
import Contracts from "./ContractsConsole"
import SellContracts from "./SellContractsConsole"
import FinancialActors from "./FinancialActors"
import NoRegOffers from "./NoRegOffers"
//TARIFF CALC
import StateConsole from "./TariffCalc/StateConsoleComponent"
import TariffTree from "./TariffCalc/TariffTree"
import OpTariff from "./TariffCalc/OpTariff"
import Reliquidations from "./TariffCalc/Reliquidations"
import PublicationTree from "./TariffCalc/PublicationTree"
import StreetLightTariff from "./TariffCalc/StreetLightTariff"
//REPORTS
import SUIReports from "./Reports/SUIReports"
import EnergyBalance from "./EnergyBalance"
import CReport from './Reports/ComercialReport';
//TECH
import ReviewCertification from "./TechOp/ReviewCertificate"
import OrderAssignation from "./TechOp/OrderAssignation"
//BILLING
import CycleParam from "./Billing/CycleParam"
import MonthlyParam from "./Billing/MonthlyParam"
import AnualParam from "./Billing/AnualParam"
import BankAccounts from "./Billing/BankAccounts"
import NetOperators from "./Billing/NetOperators"
import StreetLight from "./Billing/StreetLight"
import SecurityCollect from "./Billing/SecurityCollect"
import ClientsControl from "./Billing/ClientsControl"
import UploadConsumption from "./Billing/UploadConsumption"
import ConsumptionClientInfo from "./Billing/ConsumptionClientInfo"
import ConceptsControl from "./Billing/ConceptsControl"
import UploadConcepts from "./Billing/UploadConcepts"
import ConceptsClientInfo from "./Billing/ConceptsClientInfo"
import LiquidationOfConsumption from "./Billing/LiquidationOfConsumption"
import LiquidationST from "./Billing/LiquidationOfStreetLight"
import LiquidationSecurity from "./Billing/LiquidationSecurityCollect"
import BillGenerator from "./Billing/BillGenerator"
import InternalReports from "./Billing/InternalReports"
import BillingSUIReports from "./Billing/BillingSUIReports"
//AUTOGENERATORS
import AgreedPrice from "./Autogenerators/AgreedPrice"
import LiqConcepts from "./Autogenerators/LiqConcepts"
import NoLectureCauses from "./Autogenerators/NoLectureCauses"
import Clients from "./Autogenerators/Clients"
import ConsumptionPanel from "./Autogenerators/ConsumptionPanel"
import AGLiquidation from "./Autogenerators/AGLiquidation"
import ClientConsumption from "./Autogenerators/ClientConsumption"
import UserReport from "./Autogenerators/UserReport"
import ExportCSV from "./Autogenerators/ExportCSV"
import AGCycles from "./Autogenerators/AGCycles" 
import InternalReportFile from "./Autogenerators/InternalReportFile"
import InternalTrends from "./Autogenerators/InternalTrends"
//AG CLIENTS
import Principal from "./AGClients/Principal"
import ClientReport from "./AGClients/ClientReport"
import BalancePayment from './AGClients/BalancePayment';
/// Clients navigation
import cedenarNavigation from '../navigation/cedenarNavigation'
import spectrumNavigation from '../navigation/spectrumNavigation'

////
import {logout, authFetch} from "../auth"
import useViewport from '../hooks/useViewport';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Route, Link, useLocation, Redirect } from 'react-router-dom';
import {DatePicker} from '@material-ui/pickers';
import MuiListItem from "@material-ui/core/ListItem"
import BorderOuterIcon from '@material-ui/icons/BorderOuter';
import SvgIcon from '@material-ui/core/SvgIcon';
import Settings from '@material-ui/icons/SettingsInputComponent';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ArchiveIcon from '@material-ui/icons/Archive';
import EventIcon from '@material-ui/icons/Event';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

const ListItem = withStyles((theme) => ({
    root: {
      "&$selected": {
        backgroundColor: 'main' in theme.palette.dashboard ? theme.palette.dashboard.background : theme.palette.secondary.main,
        color: 'main' in theme.palette.dashboard ? theme.palette.primary.main : theme.palette.primary.dark,        
        borderLeft: 'main' in theme.palette.dashboard ? `10px solid ${theme.palette.dashboard.selected}` : `10px solid ${theme.palette.primary.dark}`,        
      },
      "&$selected:hover": {
        backgroundColor: theme.palette.primary.light,
        color: 'main' in theme.palette.dashboard ? theme.palette.dashboard.selected : theme.palette.secondary.light,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: 'main' in theme.palette.dashboard ? theme.palette.dashboard.selected : theme.palette.secondary.light,       
    }, 
    },
    selected: {}
  }))(MuiListItem);

const ParentListItem = withStyles((theme) => ({
    root: {
    "&$selected": {
        //backgroundColor: "#E2DDED",
        backgroundColor: 'main' in theme.palette.dashboard ? theme.palette.dashboard.background : theme.palette.secondary.main,
        color: 'main' in theme.palette.dashboard ? theme.palette.primary.main : theme.palette.primary.dark,
    },
    "&$selected:hover": {
        backgroundColor: theme.palette.primary.light,
        color: 'main' in theme.palette.dashboard ? theme.palette.dashboard.selected : theme.palette.secondary.light,
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: 'main' in theme.palette.dashboard ? theme.palette.dashboard.selected : theme.palette.secondary.light,
    }, 
    },
    selected: {}
}))(MuiListItem);

const fillNavigationState = (path, actualSection, initialSideNavFlags) => {

    for (var i = 0; i < initialSideNavFlags.length; i++){
        initialSideNavFlags[i] = false
    }

    for (var i = 0; i < actualSection.sideNav.length; i++){
        for (var j = 0; j < actualSection.sideNav[i].nav.length; j++){
            if ( path.includes(actualSection.sideNav[i].nav[j].href) ) {
                initialSideNavFlags[i] = true               
                return [initialSideNavFlags, actualSection.sideNav[i].nav[j].description]
            }
        }
    }
}

function isMobile() { return ( navigator.userAgent.match(/Tablet|iPad|Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i) ); }

const navigationFromUser = (user, role) => {    

    switch (user) {                        
        case 'SPECTRUM':
          switch (role){
            case 'dev':
              let devNavigation = spectrumNavigation.map(nav => nav.label === 'tarifario' || nav.label === 'facturador'? nav : {...nav, disabled: true})
              return devNavigation
              break;
            case'comer':
              let comerNavigation = spectrumNavigation.map(nav => nav.label === 'tarifario'? nav : {...nav, disabled: true})
              return comerNavigation
              break;
            case 'fact':
                let factNavigation = spectrumNavigation.map(nav => nav.label === 'tarifario' || nav.label === 'facturador'? nav : {...nav, disabled: true})
                return factNavigation
                break;
            case 'only-fact':
                let onlyFactNavigation = spectrumNavigation.map(nav => nav.label === 'facturador' ? nav : {...nav, disabled: true})
                return onlyFactNavigation
                break;
            case 'field-tech':
              let fieldTechNavigation = spectrumNavigation.map(nav => nav.label === 'operativo' ? 
              nav
              : {...nav, disabled: true})
              return fieldTechNavigation
              break;
            default:
              return []
          }
      
        case 'Cedenar':
            switch (role){
                case 'dev':
                    return cedenarNavigation.map(nav => nav.label === 'tarifario' ? nav : nav.label === 'Fotovoltaicos'? {...nav, sideNav: nav.sideNav.slice(0,nav.sideNav.length - 3)} : {...nav, disabled: true})
                    break;
                case 'comer':
                    return cedenarNavigation.map(nav => nav.label === 'tarifario'? nav : {...nav, disabled: true})
                    break;    
                case 'ag':
                    // 3 ES EL NUMERO DE SECCIONES EN EL MODULO DE CONSULTAS
                    return cedenarNavigation.map(nav => nav.label === 'Fotovoltaicos'?  {...nav, sideNav: nav.sideNav.slice(0,nav.sideNav.length - 3)} : {...nav, disabled: true})
                    break;      
                case 'ag_client':
                    return cedenarNavigation.map(nav => nav.label === 'Fotovoltaicos'?  {...nav, sideNav: nav.sideNav.slice(nav.sideNav.length - 3, nav.sideNav.length)} : {...nav, disabled: true})
                    break;
                case 'ag_cons':
                    return cedenarNavigation.map(nav => nav.label === 'Fotovoltaicos'?  {...nav, sideNav: nav.sideNav.slice(nav.sideNav.length - 3, nav.sideNav.length)} : {...nav, disabled: true})
                    break;  
                default:
                    return []
        }
             
      default:
          return([])
  }   
}


const useStyles = makeStyles( (theme) => (
    {
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    dialogContent: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    dialogTitle: (dimensions) =>  ({
        fontSize: 12,
        color: (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ? theme.palette.primary.main : theme.palette.secondary.main,
        fontWeight: 'normal'
    }),
    formText: {
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1)
    },
    root: {
        display: 'flex',
    },
    drawer: (dimensions) => ({
        width: dimensions.dimensions.width/7,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    }),
    drawerContainer: {
        overflowY: 'auto',
        height: "85%",
        color:  theme.palette.background.main, 
        overflow: 'hidden', 
        position: 'relative',       
    },
    settingsParent: {
        // overflowY: 'auto',
        height: "80%",
        color:  theme.palette.secondary.contrastText,   
        overflow: 'hidden', 
        position: 'relative'    
    },
    drawerTop: (dimensions) => ({
        background:  ((dimensions.role !== 'ag_client') && (dimensions.role !== 'ag_cons')) ? theme.palette.secondary.light : theme.palette.dashboard.main,
        top: 0,
        position: 'sticky',
        zIndex: 2,
    }),
    settingsChild: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: '-10px',
        height: "100%",
        overflowY: 'scroll'
    },
    verticalText:{
        fontSize: 13,
        transform: "rotate(90deg)",
        height: "100%"
    },
    contentWithDrawerWithSettings: (dimensions) => ({
        marginTop: dimensions.dimensions.height/6.5,
        flexGrow: 1,
        paddingLeft: theme.spacing(1),     
        paddingRight: theme.spacing(1),   
        [theme.breakpoints.down('xs')]: {
            marginLeft: '100%',
            marginRight: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '30%',
            marginRight: '30%',
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: dimensions.dimensions.width/7,
            marginRight: dimensions.dimensions.width/7
        },                      
    }),
    contentWithDrawerWithoutSettings: (dimensions) => ({
        marginTop: (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ? dimensions.dimensions.height/6.5 : dimensions.dimensions.height/17,
        flexGrow: 1,
        paddingLeft: theme.spacing(1),       
        [theme.breakpoints.down('xs')]: {
            display: 'None',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '30%',
            //marginTop: '15%'
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: dimensions.dimensions.width/7,
            //marginTop: dimensions.dimensions.height/6.5,
        },   
        marginRight: (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ? dimensions.dimensions.width/24 : 0,
        paddingRight: theme.spacing(1)             
    }),
    contentWithoutDrawerWithSettings: (dimensions) => ({
        marginTop: dimensions.dimensions.height/6.5,
        flexGrow: 1,
        paddingRight: theme.spacing(1),       
        [theme.breakpoints.down('xs')]: {
            marginRight: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: '30%',
        },
        [theme.breakpoints.up('md')]: {
            marginRight: dimensions.dimensions.width/7
        },   
        marginLeft: dimensions.dimensions.width/18,
        paddingLeft: theme.spacing(1)  
    }),
    contentWithoutDrawerWithoutSettings: (dimensions) => ({
        marginTop: isMobile() ? (window.screen.orientation.type.includes('landscape')) ? dimensions.dimensions.height/4 : dimensions.dimensions.height/8 : (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ? dimensions.dimensions.height/6.5 : dimensions.dimensions.height/17,
        flexGrow: 1,
        marginRight: isMobile() ? 0 : dimensions.dimensions.width/18,
        marginLeft: dimensions.dimensions.width/18,       
        paddingRight: isMobile() ? 0 : theme.spacing(1), 
        paddingLeft: theme.spacing(1),
        marginRight: (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ? dimensions.dimensions.width/24 : 0,
    }),
    toolbarTop: (dimensions) => ({
        height: (window.screen.orientation.type.includes('landscape')) && isMobile() ? dimensions.dimensions.height/10 : dimensions.dimensions.height/17,
        background: theme.palette.client.main,        
        color: theme.palette.client.contrastText,
        minHeight: 0,
        padding: 0        
    }),
    toolbarBottom: () => ({
        //height: (window.screen.orientation.type.includes('landscape')) && isMobile() ? dimensions.dimensions.height/9 : dimensions.dimensions.height/16,
        background: theme.palette.secondary.light,
        padding: 0,
        minHeight: 0,
        borderBottom: `2px solid ${theme.palette.secondary.dark}`,   
    }),
    tab: (dimensions) => ({
        fontSize: (dimensions.dimensions.width >= 800) ? 14 : (dimensions.dimensions.width >= 500) ? 11 : (dimensions.dimensions.width/55 ) + 6,
        //height: (window.screen.orientation.type.includes('landscape')) && isMobile() ? dimensions.dimensions.height/9 : dimensions.dimensions.height/16,
        //maxHeight: (window.screen.orientation.type.includes('landscape')) && isMobile() ? dimensions.dimensions.height/9 : dimensions.dimensions.height/16,
        //minHeight: (window.screen.orientation.type.includes('landscape')) && isMobile() ? dimensions.dimensions.height/9 : dimensions.dimensions.height/16, 
        color: theme.palette.primary.dark, 
        padding: 0,      
        width: dimensions.dimensions.width/7,
        margin: 0,
        '&:hover': {
            background: (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ? theme.palette.primary.main : theme.palette.dashboard.main,
            color: theme.palette.secondary.light,
            //borderBottom: `2px solid ${theme.palette.secondary.main}`,        
         },               
    }),   
    tabOn: {       
        padding: 0,
        borderBottom: `5px solid ${theme.palette.primary.dark}`,        
        //background: theme.palette.primary.dark,
        color: theme.palette.primary.dark, 
    },
    appBarLogoSection: (dimensions) => ({
        paddingLeft: 5,
        fontSize: (dimensions.dimensions.width >= 600) ? 16 : (dimensions.dimensions.width/51) + 6,
        fontWeight: 'bold',         
    }),   
    appBarUserSection: (dimensions) => ({        
        fontSize: (dimensions.dimensions.width >= 600) ? 15 : (dimensions.dimensions.width/55) + 5,                
    }),
    appBarUserSectionLogo: (dimensions) => ({
        fontSize: (dimensions.dimensions.width >= 600) ? 30 : (dimensions.dimensions.width/25) + 4,
    }),
    appBarUserMenu: (dimensions) => ({
        fontSize: (dimensions.dimensions.width >= 600) ? 18 : (dimensions.dimensions.width/48) + 4,
        marginTop: dimensions.dimensions.height/18
    }),    
    drawerOpen: (dimensions) => ({
        backgroundColor: (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ? theme.palette.background.main : theme.palette.dashboard.main,
        marginTop: 
            (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ?
                ( (window.screen.orientation.type.includes('landscape')) && isMobile() ) ? dimensions.dimensions.height/4 : dimensions.dimensions.height/6.75
            :
            dimensions.dimensions.height/16,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
        [theme.breakpoints.up('sm')]: {
            width: dimensions.dimensions.width/4,
            fontSize: 11,
        },
        [theme.breakpoints.up('md')]: {
            width: dimensions.dimensions.width/7
        },
        overflowY: 'hidden',
        height: "100%",
        '*::-webkit-scrollbar': {
            width: '0%'
          },
        marginBottom: 0,        
    }),    
    hiddenScrollbar: {
        overflow: 'hidden',
        height: "100%"
    },
    drawerClose: (dimensions) => ({
        backgroundColor: (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ? theme.palette.background.main : theme.palette.dashboard.main,
        marginTop: 
            (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ?
                ( (window.screen.orientation.type.includes('landscape')) && isMobile() ) ? dimensions.dimensions.height/4 : dimensions.dimensions.height/6.75
            :
            dimensions.dimensions.height/16,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: dimensions.dimensions.width/18,
        [theme.breakpoints.down('xs')]: {
          width: theme.spacing(4) + 1,
        },  
    }),
    nested: {
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
        },
    },
    noNested: {
        paddingLeft: theme.spacing(0),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
        },
    },
    sideNavText: (dimensions) => ( {
        fontSize: (dimensions.dimensions.width >= 800) ? 14 : (dimensions.dimensions.width >= 500) ? 11 : (dimensions.dimensions.width/55 ) + 6,    
        color: (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ? theme.palette.primary.dark : theme.palette.primary.main,         
    }),
    sideNavTextLast: (dimensions) => ( {
        fontSize: (dimensions.dimensions.width >= 800) ? 14 : (dimensions.dimensions.width >= 500) ? 11 : (dimensions.dimensions.width/55 ) + 6,    
        color: (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ? theme.palette.primary.dark : theme.palette.primary.main,
        marginBottom: 0         
    }),
    sideNavTextSelected: (dimensions) => ( {        
        borderLeft: (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ? `10px solid ${theme.palette.primary.dark}` : `10px solid ${theme.palette.dashboard.selected}`,        
        color: theme.palette.primary.dark,          
    }),
    sideNavOptionLogoSize: (dimensions) => ({
        padding: 0,
        margin: 0,
        minWidth: '50px',
        fontSize: (dimensions.dimensions.width >= 600) ? 20 : (dimensions.dimensions.width/40 ) + 4,
    }),
    sideNavOptionChildLogoSize: (dimensions) => ({
        padding: 0,
        margin: 0,
        minWidth: '50px',
        fontSize: (dimensions.dimensions.width >= 600) ? 16 : (dimensions.dimensions.width/45 ) + 4,
    }),
    closedSideNavLogos: (dimensions) => ({
        padding: 0,
        marginBottom: theme.spacing(2),
        minWidth: '50px',
        [theme.breakpoints.down('xs')]: {
            minWidth: '20px',
        },
        fontSize: (dimensions.dimensions.width >= 600) ? 20 : (dimensions.dimensions.width/40 ) + 4,
    }),
    sideNavOptionLogo:{
        color:theme.palette.primary.dark,
        "&:hover": {
            color: theme.palette.primary.contrastText
        }
    },
    sideNavCloseText: (dimensions) => ({
        fontSize: (dimensions.dimensions.width >= 600) ? 10 : (dimensions.dimensions.width/150 ) + 4,
        fontWeight: 'bold'
    }),    
    formRow: {
        width: "100%",       
        maxHeight: "50%",  
        zIndex: 1       
    },
    formText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
        marginLeft: theme.spacing(1),       
        zIndex: 1 
    },
    itemText: {
        fontSize: 13,
        color: theme.palette.primary.main,
        textAlign: "center",         
    },
    formDefined: {
        backgroundColor: theme.palette.secondary.light,        
    },
    formDateText: {
        fontSize: 12,
        color: theme.palette.primary.main,
        textAlign: "left",
    },
    darkColor: (dimensions) => ({
        color: (dimensions.role !== 'ag_client' && dimensions.role !== 'ag_cons') ? theme.palette.primary.dark : theme.palette.primary.main,
    })
}));

const selectTxf = [
    {label: ".tx2"}, {label: ".tx3"},{label: ".txr"}, {label: ".txf"}, 
]

export default function MainNav(props) {
    var aux = 0
    var windowDim = useViewport()
    var currentRoute = useLocation()
    const windowWidth   = windowDim[0]
    const windowHeight  = windowDim[1]
    let dimensions = {height: windowHeight, width: windowWidth}
    const path = currentRoute.pathname       
    const actualSection = navigationFromUser(props.username, props.role).filter(section => path.includes(section.href))[0]    
    let initialSideNavFlags = new Array(actualSection.sideNav.length)
    const navState = fillNavigationState(path, actualSection, initialSideNavFlags)    
    initialSideNavFlags = navState[0]

    const classes = useStyles({dimensions: dimensions, role: props.role})
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDrawer, setOpenDrawer] = React.useState(windowWidth >= 600 ? true : false);
    const [openSettings, setOpenSettings] = React.useState( actualSection.href.includes('principal') || actualSection.href.includes('facturador')
    || (actualSection.href.includes('autogeneradores') && !(path.includes('/consultas/'))) ? true : false)
    const [markets, setMarkets] = React.useState([])  
    const [marketsDemand, setMarketsDemand] = React.useState([])
    const [marketOpTariff, setMarketOpTariff] = React.useState({})
    const [marketsOpTariff, setMarketsOpTariff] = React.useState([])
    const [userType, setUserType] = React.useState(props.username === 'SPECTRUM' ? 'No Regulados' : 'Regulados'); 
    // Valor de prc ventas para los STR
    const [strDist, setStrDist] = React.useState([]) 

    const [tariffCalcVersion, setTariffCalcVersion] = React.useState("")
    const [txfVersion, setTxfVersion] = React.useState("");
    const [tariffsPublished, setTariffsPublished] = React.useState(false);
    const [opTariffPub, setOpTariffPub] = React.useState(false)
    const [calcType, setCalcType] = React.useState("")
    const [definitiveVersion, setDefinitiveVersion] = React.useState(false)
    const [reliqSelectedPeriod, setReliqSelectedPeriod] = React.useState(null)
    const [reliqTxfVersion, setReliqTxfVersion] = React.useState("")   
    const [reliqLastTxfVersion, setReliqLastTxfVersion] = React.useState("") 
    const [reliqFinished, setReliqFinished] = React.useState(true)

    const [tariffCalcVersions, setTariffCalcVersions] = React.useState([])
    const [txfVersions, setTxfVersions] = React.useState([]);
    const [tariffsPublisheds, setTariffsPublisheds] = React.useState([]);
    const [calcTypes, setCalcTypes] = React.useState([])
    const [usedXmVersions, setUsedXmVersions] = React.useState([])
    

    const [sideNavFlags, setSideNavFlags] = React.useState( initialSideNavFlags )
    const [isLoading, setIsLoading] = React.useState(true)
    const [passwordChange, setPasswordChange] = React.useState(false)

    const [actualPassword, setActualPassword] = React.useState("")
    const [actualPasswordState, setActualPasswordState] = React.useState(false)
    const [actualPasswordHelper, setActualPasswordHelper] = React.useState("")

    const [newPassword, setNewPassword] = React.useState("")
    const [newPasswordState, setNewPasswordState] = React.useState(false)
    const [newPasswordHelper, setNewPasswordHelper] = React.useState("")

    const [repeatedNewPassword, setRepeatedNewPassword] = React.useState("")
    const [repeatedNewPasswordState, setRepeatedNewPasswordState] = React.useState(false)
    const [repeatedNewPasswordHelper, setRepeatedNewPasswordHelper] = React.useState("")

    const [togglePassword, setTogglePassword] = React.useState(true)
    

    const handlePasswordDialogOpen = () => {
        setPasswordChange(true);
      };
        
    const handlePasswordDialogClose = () => {
        setPasswordChange(false);
    };

    const validateActualPassword = (text) => {
        if (text.length === 0){
            setActualPasswordState(false)
            setActualPasswordHelper("Ingrese la contraseña actual")
        }
        else if (text.length < 8){
            setActualPasswordState(false)
            setActualPasswordHelper("La contraseña debe contener al menos 8 caracteres")
        }
        else {
            setActualPasswordState(true)
            setActualPasswordHelper("")
        }
    }

    const validateNewPassword = (text) => {
        if (text.length === 0){
            setNewPasswordState(false)
            setNewPasswordHelper("El campo no puede estar vacío")
        }
        else if ( (!/[0-9]/.test(text) || !/[A-Z]/.test(text)) && text.length >= 8){
            setNewPasswordState(false)
            setNewPasswordHelper("La contraseña debe contener al menos una mayusc. [A-Z] y un número [0-9]")
        }
        else if (text.length < 8){
            setNewPasswordState(false)
            setNewPasswordHelper("La contraseña debe contener al menos 8 caracteres")
        }
        else if (text === actualPassword){
            setNewPasswordState(false)
            setNewPasswordHelper("La contraseña no debe ser igual a la anterior")
        }        
        else {
            setNewPasswordState(true)
            setNewPasswordHelper("")
        }
    }

    const validateRepeatedNewPassword = (text, flag, firstPw) => {
        if (text.length === 0){
            setRepeatedNewPasswordState(false)
            setRepeatedNewPasswordHelper("El campo no puede estar vacío")
        }
        else if ( (!/[0-9]/.test(text) || !/[A-Z]/.test(text)) && text.length >= 8){
            setRepeatedNewPasswordState(false)
            setRepeatedNewPasswordHelper("La contraseña debe contener al menos una mayusc. [A-Z] y un número [0-9]")
        }
        else if (text.length < 8){
            setRepeatedNewPasswordState(false)
            setRepeatedNewPasswordHelper("La contraseña debe contener al menos 8 caracteres")
        }
        else if ( (/[0-9]/.test(text) && /[A-Z]/.test(text)) && text.length >= 8 && text !== firstPw){
            setRepeatedNewPasswordState(false)
            setRepeatedNewPasswordHelper("Las contraseñas deben coincidir")
        }
        else {
            setRepeatedNewPasswordState(true)
            setRepeatedNewPasswordHelper("")
        }
    }

    const changePasswordValue = () => {
        let opts = {
            'email': props.user,
            'password': actualPassword,
            'new_password': repeatedNewPassword
        }
        authFetch('/changePassword', {
            method: 'post',
            body: JSON.stringify(opts)
          }).then(r => {
              if (r.status === 422){
                logout()
                return null
              }
              else{
                return r.json()
              }
            })
            .then(token => {
              if ('password_changed' in token){
                alert("Contraseña cambiada con éxito. Inicie sesión nuevamente.")
                logout()                   
              }
              else if ('repeated_password' in token) {        
                alert("Contraseña ya usada anteriormente. Ingrese una nueva.")
              }
              else if ('invalid_password' in token) {        
                alert("Contraseña incorrecta.")         
              }
      
            })
    }

    const showPasswordDialog = () => (
        <Dialog
            open={passwordChange}
            onClose={handlePasswordDialogClose}       
            className={classes.formText}   
            maxWidth="xs"         
        >
        <DialogTitle className={classes.dialogTitle} style={{textAlign: 'center'}}>Cambio de contraseña</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={9}>
                        <TextField
                            variant="outlined"
                            id="actual-pw"
                            required
                            label="Contraseña actual"
                            type={togglePassword ? "password" : "text"}
                            fullWidth
                            onChange={(e) => {setActualPassword(e.target.value); validateActualPassword(e.target.value);}}
                            error={!actualPasswordState}
                            helperText={actualPasswordHelper}
                            color={props.role !== 'ag_client' && props.role !== 'ag_cons' ? "primary" : "secondary"}
                            InputProps = {
                            {
                                endAdornment: ( 
                                <InputAdornment position="end"> {
                                    togglePassword ?
                                    ( 
                                    <VisibilityOff 
                                        onMouseDown={(e) => {setTogglePassword(false)}}
                                        onMouseUp={(e) => {setTogglePassword(true)}}
                                    />
                                    ) 
                                    :
                                    ( 
                                    <Visibility 
                                        className = "cursor_pointer"                                        
                                        onMouseUp={(e) => {setTogglePassword(true)}}
                                    />
                                    ) 
                                } 
                                </InputAdornment>
                                ),
                            }
                            }
                        >

                        </TextField>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            variant="outlined"
                            id="new-pw"
                            required
                            label="Contraseña nueva"
                            type={togglePassword ? "password" : "text"}
                            onChange={(e) => {setNewPassword(e.target.value); validateNewPassword(e.target.value); validateRepeatedNewPassword(repeatedNewPassword, false, e.target.value);}}
                            error={!newPasswordState}
                            helperText={newPasswordHelper}
                            fullWidth
                            color={props.role !== 'ag_client' && props.role !== 'ag_cons' ? "primary" : "secondary"}
                            InputProps = {
                            {
                                endAdornment: ( 
                                <InputAdornment position="end"> {
                                    togglePassword ?
                                    ( 
                                    <VisibilityOff 
                                        onMouseDown={(e) => {setTogglePassword(false)}}
                                        onMouseUp={(e) => {setTogglePassword(true)}}
                                    />
                                    ) 
                                    :
                                    ( 
                                    <Visibility 
                                        className = "cursor_pointer"                                        
                                        onMouseUp={(e) => {setTogglePassword(true)}}
                                    />
                                    ) 
                                } 
                                </InputAdornment>
                                ),
                            }
                            }
                        >

                        </TextField>
                    </Grid>
                    {
                        newPasswordState ? 
                        <Grid item xs={9}>
                        <TextField
                            variant="outlined"
                            id="repeat-new-pw"
                            required
                            label="Repita contraseña nueva"
                            onChange={(e) => {setRepeatedNewPassword(e.target.value); validateRepeatedNewPassword(e.target.value, true, newPassword);}}
                            error={!repeatedNewPasswordState}
                            helperText={repeatedNewPasswordHelper}
                            type={togglePassword ? "password" : "text"}
                            fullWidth
                            color={props.role !== 'ag_client' && props.role !== 'ag_cons' ? "primary" : "secondary"}
                            InputProps = {
                            {
                                endAdornment: ( 
                                <InputAdornment position="end"> {
                                    togglePassword ?
                                    ( 
                                    <VisibilityOff 
                                        onMouseDown={(e) => {setTogglePassword(false)}}
                                        onMouseUp={(e) => {setTogglePassword(true)}}
                                    />
                                    ) 
                                    :
                                    ( 
                                    <Visibility 
                                        className = "cursor_pointer"                                        
                                        onMouseUp={(e) => {setTogglePassword(true)}}
                                    />
                                    ) 
                                } 
                                </InputAdornment>
                                ),
                            }
                            }
                        >

                        </TextField>
                        </Grid>
                        :
                        null
                    }
                    
                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handlePasswordDialogClose} variant="contained" color={props.role !== 'ag_client' && props.role !== 'ag_cons' ? "primary" : "secondary"} autoFocus>
            Cancelar
          </Button>
          <Button onClick={changePasswordValue} variant="contained" color={props.role !== 'ag_client' && props.role !== 'ag_cons' ? "primary" : "secondary"} autoFocus disabled={!actualPasswordState || !newPasswordState || !repeatedNewPasswordState}>
            Actualizar
          </Button>
        </DialogActions>
        </Dialog>
    )

    const handleSideNavClick = (idx) => () => {
        let auxFlags = []
        for (var i = 0; i < actualSection.sideNav.length; i++){
            auxFlags.push(false)
        }
        if (! sideNavFlags[idx]) {
            auxFlags[idx] = !auxFlags[idx]
        }      
        setSideNavFlags(auxFlags)
    };

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleSettingsOpen = () => {
        setOpenSettings(true);
    };

    const handleSettingsClose = () => {
        setOpenSettings(false);
    };
    
    const handleTabsChange = () => {
        let newFlags = new Array(actualSection.sideNav.length)
        for (var i = 0; i < newFlags.length; i++){
            if (i == 0){newFlags[i] = true}
            else { newFlags[i] = false}
        }            
        setSideNavFlags(newFlags)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleMarketChange = (event) => {        
        let idx = markets.indexOf(event.target.value)
        props.setMarket(event.target.value)  
        props.setMarketDemand(marketsDemand[idx])   
        setMarketOpTariff ( marketsOpTariff[idx] !== null ? JSON.parse(marketsOpTariff[idx]) : {})           
    }

    const handleUserTypeChange = (event) => {
        setUserType(event.target.value)
    }

    // Navegacion superior
    const navOptions = () => {                                        
        return (
            navigationFromUser(props.username, props.role).map( (option,idx) => (
                <Tab key = {option.label} to={option.sideNav[0].nav[0].href} 
                    component={Link} label = {option.label} value={idx} disabled={option.disabled}
                    classes={{
                        selected: classes.tabOn,
                        root: classes.tab
                    }}
                >
                </Tab>
            ))
        )                            
    }

    // Navegacion lateral
    const sideNavOptions = (sideNavFlags, path, actualSection) => {        
        return (
            props.username === 'SPECTRUM' && userType === 'No Regulados' && actualSection.label === 'tarifario' ? 

            actualSection.sideNav.filter(nav => nav.title === 'Cálculo de tarifas' || nav.title === 'Ajustes internos' || nav.title === 'Insumos' || nav.title === 'Reportes').map( (option, idx) => (
                
                <React.Fragment key={idx} style={option.title === 'Reportes' ? {marginBottom: 0} : null}>
                    <ParentListItem classes={{root: classes.sideNavText, selected: classes.sideNavTextSelected}} 
                        selected={sideNavFlags[idx]} onClick={handleSideNavClick(idx)} className={classes.noNested}
                        >
                        <SvgIcon className={classes.sideNavOptionLogoSize}>
                            {option.icon}
                        </SvgIcon>
                        <ListItemText disableTypography primary={option.title} />
                        {sideNavFlags[idx] ? <ExpandLess /> : <ExpandMore />}
                    </ParentListItem>
                    <Collapse in={sideNavFlags[idx]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding >                            
                                {option.nav.filter(nav => nav.label === 'Consola de componentes' || nav.label === 'Mercados' || nav.label === 'Archivos' 
                                                    || nav.label === 'Parámetros' || nav.label === 'Ofertas no regulados' || nav.label === 'Precio de bolsa no regulados' || nav.label === 'Balance energético').map ( (nav,idx) => (
                                                    <React.Fragment  key={idx}>
                                                        <ListItem selected={path.includes(nav.href) ? true : false} button component={Link} to={nav.href} classes={{root: classes.sideNavText}} className={classes.nested}>
                                                            <FiberManualRecordIcon className={classes.sideNavOptionChildLogoSize}/>
                                                        <ListItemText disableTypography primary={nav.label} />
                                                        </ListItem>
                                                    </React.Fragment>
                                ))}                                                              
                        </List>
                    </Collapse>
                    {/* {                       
                        <Redirect to={"/webspectrum/principal/ofertas-no-regulados"}/>                                              
                    } */}
                </React.Fragment>
            ))
            :
            actualSection.sideNav.map( (option, idx) => (
                <React.Fragment key={idx} >
                    <ParentListItem classes={{root: classes.sideNavText, selected: classes.sideNavTextSelected}} selected={sideNavFlags[idx]} onClick={handleSideNavClick(idx)} className={classes.noNested}>
                        <SvgIcon className={classes.sideNavOptionLogoSize}>
                            {option.icon}
                        </SvgIcon>
                        <ListItemText disableTypography primary={option.title} />
                        {sideNavFlags[idx] ? <ExpandLess /> : <ExpandMore />}
                    </ParentListItem>
                    <Collapse in={sideNavFlags[idx]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding >                            
                                {option.nav.filter(nav => nav.label !== 'Ofertas no regulados' && nav.label !== 'Precio de bolsa no regulados').map ( (nav,idx) => (
                                    <React.Fragment  key={idx}>
                                        <ListItem selected={path.includes(nav.href) ? true : false} button component={Link} to={nav.href} classes={{root: classes.sideNavText}} className={classes.nested}>
                                            <FiberManualRecordIcon className={classes.sideNavOptionChildLogoSize}/>
                                        <ListItemText disableTypography primary={nav.label} />
                                        </ListItem>
                                    </React.Fragment>
                                ))}                                                              
                        </List>
                    </Collapse>
                    {/* {
                        actualSection.label === 'tarifario' ?                    
                        <Redirect to={props.validURLs[0]}/>                    
                        :
                        null                         
                    } */}
                </React.Fragment>
            ))
        )
    }

    const closeSideNavLogos = (actualSection) => {
        return(
            actualSection.sideNav.map ((option, idx) => (
                <React.Fragment key={idx}>
                    <SvgIcon className={classes.closedSideNavLogos} color="primary" size="small">
                        {option.icon}
                    </SvgIcon>
                </React.Fragment>
            ))           
        )
    }

    React.useEffect (() => {
        setIsLoading(true)
        //document.title ="Gestión Tarifaria";  
        if (props.role !== 'ag_client' && props.role !== 'ag' && props.role !== 'ag_cons'){     
            authFetch("/api/get-version-info?username=" + String(props.username) + "&period=" + String( props.periodDate.getMonth() + 1 )
            + "&year=" + props.periodDate.getFullYear()).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                }).then(response => {
                    if (response && response.txfVersions){                    
                        if (response.txfVersions.length > 0){                        
                            setTxfVersions(response.txfVersions)
                            setTxfVersion(response.txfVersions[0])    
                            setTariffCalcVersions(response.versions)
                            setTariffCalcVersion(response.versions[0])
                            setTariffsPublisheds(response.tariffsPublished)
                            setTariffsPublished(response.tariffsPublished[0])                            
                            setCalcTypes(response.calcTypes)                        
                            setCalcType(response.calcTypes[0])
                            setDefinitiveVersion(response.definitiveVersion)
                            setReliqTxfVersion(response.rlqVersion)
                            setReliqLastTxfVersion(response.lastVersion)
                            setReliqFinished(response.rlqFinished)
                            setUsedXmVersions(response.usedXmVersions)
                            setTimeout(() => {setIsLoading(false)}, 200)
                        }      
                        else {
                            setTxfVersions([])
                            setTxfVersion("")    
                            setTariffCalcVersions([])
                            setTariffCalcVersion("")
                            setTariffsPublisheds([])
                            setTariffsPublished(false)
                            setCalcTypes([])                        
                            setCalcType("")      
                            setDefinitiveVersion(false) 
                            setReliqTxfVersion("")
                            setReliqLastTxfVersion("")
                            setReliqFinished(true)
                            setUsedXmVersions([])       
                            setTimeout(() => {setIsLoading(false)}, 200)                    
                        }
                        ////console.log('res', response)
                    }
                    else{
                        setTimeout(() => {setIsLoading(false)}, 200)
                    }
                })
            if (markets.length === 0){
                authFetch("/api/check-markets?username=" + String(props.username) ).then(response => {
                    if (response.status === 422){
                        logout()
                        return null
                    }
                        return response.json()
                    }).then(response => {
                        if (response && response.markets){
                            let activeMarkets = response.markets.flatMap((market) => market.state ? market.name : [])
                            let marketsDemandPerc = response.markets.flatMap((market) => market.state ? market.perc_energy_demand : [])  
                            let marketOpTariffs =  response.markets.flatMap((market) => market.state ? market.tariff_option_params : [])                 
                            setMarkets( activeMarkets )        
                            setMarketsDemand ( marketsDemandPerc )
                            setMarketsOpTariff ( marketOpTariffs )
                            setMarketOpTariff ( marketOpTariffs[0] !== null ? JSON.parse(marketOpTariffs[0]) : {})
                            props.setMarket( activeMarkets[0] )  
                            props.setMarketDemand ( marketsDemandPerc[0] )   
                            setStrDist([response.nstr, response.csstr])       
                            //props.setMarket(response.markets[0].name)
                        }
                    })
            }       
        } 
        else{
            setTimeout(() => {setIsLoading(false)}, 200)   
        }
    }, [props.periodDate])


    React.useEffect (() => {    
        if (props.role !== 'ag_client' && props.role !== 'ag' && props.role !== 'ag_cons'){    
            if (props.market != "" && tariffCalcVersion != ""){
                authFetch("/api/calc-op-tariff?username=" + String(props.username) + "&period=" + String( props.periodDate.getMonth() + 1 )
                + "&year=" + props.periodDate.getFullYear() + "&market=" + String( props.market ) + "&version=" + String( tariffCalcVersion )
                ).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                }).then(response => {
                    if (response && response.OpTariff){
                        ////console.log('Componentes', response.Components)      
                        // Solo se usa para observar si la opcion tarifaria ya esta publicada                                 
                        setOpTariffPub(response.OpTariff[0].Published)
                    }
                })
            }
        }
        
    }, [props.market, tariffCalcVersion, props.periodDate])


    React.useEffect(() => {        
        authFetch("/get-password-validity?email=" + String(props.user)
                ).then(response => {
                if (response.status === 422){
                    logout()
                    return null
                }
                    return response.json()
                }).then(response => {
                    if (response && response.validity){                        
                        let date = new Date()
                        let valDate = new Date(response.validity)
                        if (date > valDate){                        
                            alert('Por favor cambiar la contraseña. El periodo de uso ha expirado.')
                            handlePasswordDialogOpen()
                        }
                    }
                })
    },[])

    return (
        <div className={classes.root}>
            {showPasswordDialog()}           
            <Grid container>
                <Grid item xs={12}>                                        
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar className={classes.toolbarTop}>
                            <Grid container direction="row" justify="flex-end" alignItems="center">
                                <Grid className={classes.appBarLogoSection} item xs={1}>
                                    {props.appName}
                                </Grid>   
                                <Grid item xs={7} md={9}></Grid>                                                 
                                <Grid className={classes.appBarUserSection} item xs={4} md={2}>
                                    
                                    {props.role !== 'ag_client' && props.role !== 'ag_cons' ? " Bienvenido: " + props.username : 'Bienvenido'}
                                    {/* {"Bienvenido"} */}
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        color="inherit"
                                        size={isMobile() ? 'small' : 'medium'}
                                    >
                                        <AccountCircle className={classes.appBarUserSectionLogo}/>
                                    </IconButton>
                                    <Menu
                                        id="profile-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        className={classes.appBarUserMenu}                                
                                    >
                                        <MenuItem onClick={() => {setPasswordChange(true); handleClose();}}>
                                            <ListItemIcon>
                                                <SettingsApplicationsRoundedIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText  primary="Cambiar contraseña" />
                                        </MenuItem>
                                        <MenuItem onClick={logout}>
                                            <ListItemIcon>
                                                <ExitToAppRoundedIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary="Salir" />
                                        </MenuItem>
                                    </Menu>                                     
                                </Grid>
                            </Grid>                           
                        </Toolbar>
                        {
                            props.role !== 'ag_client' && props.role !== 'ag_cons' ?
                            <Toolbar className={classes.toolbarBottom}>
                                <Grid container direction="row" alignItems="center">                                                     
                                    <Tabs
                                        variant="scrollable"
                                        value={actualSection.idx} 
                                        //onChange={() => {handleTabsChange(); handleSettingsClose();}}    
                                        onChange={() => {handleTabsChange();}}                                 
                                        aria-label="nav tabs example"
                                        indicatorColor="secondary"
                                        scrollButtons="auto"
                                        TabIndicatorProps={{className: classes.tabIndicator}}                                  
                                    >
                                        {navOptions(props.username, props.role)}
                                    </Tabs>                                    
                                                            
                                </Grid>                           
                            </Toolbar>
                            :
                            null
                        }
                    </AppBar>
                                        
                    {/* izq */}
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: openDrawer,
                                [classes.drawerClose]: !openDrawer,
                        })
                        }}                       
                    >
                        <div className={classes.drawerContainer}>
                            <div className={classes.settingsChild}>
                                <div className={classes.drawerTop}>
                                    <IconButton className={classes.darkColor} onClick={openDrawer ? handleDrawerClose : handleDrawerOpen} size={openDrawer ? 'medium':'small'}>
                                        {!openDrawer ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                    </IconButton>
                                </div>
                                <Divider />                        
                                <Divider />
                                    <Grid container direction="column" justifyContent='space-between'>                                   
                                    <Grid item xs={12}>
                                    <List className={classes.noNested}>
                                    { openDrawer? sideNavOptions(sideNavFlags, path, actualSection) :  closeSideNavLogos(actualSection)}
                                    </List>                                     
                                    </Grid>
                                    {/* <Grid item style={{position: 'absolute', bottom: '0%'}} xs={12}>
                                    <ParentListItem 
                                        classes={{root: classes.sideNavText, selected: classes.sideNavTextSelected}} 
                                        className={classes.noNested}                                        
                                        onClick={(e) => {logout()}}>  
                                        <Divider /> 
                                        <SvgIcon className={classes.sideNavOptionLogoSize}>
                                            {<ExitToAppRoundedIcon/>}
                                        </SvgIcon>
                                        <ListItemText disableTypography primary={"Salir"} />                                                  
                                    </ParentListItem>
                                    </Grid>     */}
                                    </Grid>                                                   
                            </div>                                                     
                        </div>
                    </Drawer>
                    
                    {/* Filtro para comerciales der*/}
                    {
                        actualSection.href.includes('principal') || actualSection.href.includes('facturador') || ( actualSection.href.includes('autogeneradores') && !(path.includes('/consultas/')) )? 
                        <Drawer
                            anchor="right"
                            variant="permanent"
                            classes={{
                            paper: clsx({
                                [classes.drawerOpen]: openSettings,
                                [classes.drawerClose]: !openSettings,
                            })
                            }} 
                        >
                            <div className={classes.settingsParent}>
                                <div className={classes.settingsChild}>                            
                                    <div className={classes.drawerTop}>
                                        <IconButton className={classes.darkColor} size={openSettings ? 'medium':'small'} onClick={openSettings ? handleSettingsClose : handleSettingsOpen}>
                                            {!openSettings ?  <ChevronLeftIcon /> : <ChevronRightIcon />}
                                        </IconButton>
                                    </div>
                                    <Divider />                        
                                    <Divider /> 
                                    {
                                        openSettings ? 
                                            actualSection.href.includes('principal') ?
                                            <Grid container justify="space-evenly" alignItems="center" style={{marginTop: 5, padding: 10}}>       
                                                <Grid item>
                                                    <BorderOuterIcon fontSize="small"/>
                                                </Grid>  
                                                <Grid item xs={9}>
                                                    <Typography variant="subtitle2" align="left">Mercado</Typography>
                                                </Grid>                                                                                                       
                                                <Grid item xs={12} style={{marginTop: 10}}>
                                                    <TextField
                                                        select
                                                        value={props.market}
                                                        onChange={handleMarketChange}                                        
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.formText
                                                            }
                                                        }}
                                                        className={classes.formRow} 
                                                    >  
                                                        { 
                                                            markets.length != 0 ?                                             
                                                            markets.map((option,idx) => (
                                                                <MenuItem key={idx} value={option} className={classes.itemText}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))
                                                            :
                                                            <div>/</div>
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item style={{marginTop: 20}}>
                                                    <LocationCityIcon fontSize="small"/>
                                                </Grid>  
                                                <Grid item xs={9} style={{marginTop: 20}}>
                                                    <Typography variant="subtitle2" align="left">Tipo de Usuario</Typography>
                                                </Grid>                                                                                                       
                                                <Grid item xs={12} style={{marginTop: 10}}>
                                                    <TextField
                                                        select
                                                        value={userType}
                                                        onChange={handleUserTypeChange}                                        
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.formText
                                                            }
                                                        }}
                                                        className={classes.formRow} 
                                                    >        
                                                        {
                                                            props.username === 'SPECTRUM' ?
                                                            null
                                                            :
                                                            <MenuItem value={"Regulados"} className={classes.itemText}>
                                                                {"Regulados"}
                                                            </MenuItem>
                                                            
                                                        }  
                                                        {
                                                            props.username === 'Cedenar' ?
                                                            null
                                                            :
                                                            ['Regulados', 'No Regulados'].map((opt, idx) => (
                                                                <MenuItem key={idx} value={opt} className={classes.itemText}>
                                                                {opt}
                                                            </MenuItem>
                                                            ))                                                            
                                                        }                                                                                                                                                                                                          
                                                    </TextField>
                                                </Grid>
                                                <Grid item style={{marginTop: 20}}>
                                                    <EventIcon fontSize="small"/>
                                                </Grid>  
                                                <Grid item xs={9} style={{marginTop: 20}}>
                                                    <Typography variant="subtitle2" align="left">{'Periodo de insumos'}</Typography>
                                                </Grid>                                                                                                       
                                                <Grid item xs={12} style={{marginTop: 10}}>
                                                    <DatePicker    
                                                        variant="dialog"    
                                                        inputVariant="standard"                                                                                 
                                                        okLabel={""}
                                                        cancelLabel={""}
                                                        openTo="month"
                                                        format="MMMM-yyyy" 
                                                        minDate={new Date(2021,11,1)}   
                                                        maxDate={new Date(2025,11,1)}                                        
                                                        views={["year","month"]}                                           
                                                        value={props.periodDate}                                           
                                                        onChange={(date) => { props.setPeriodDate(date);}}
                                                        error={false}
                                                        InputProps={{                                            
                                                                    classes: {
                                                                        input: classes.formText,                                    
                                                                    }
                                                                }}                                                
                                                        autoOk    
                                                        fullWidth                                                                              
                                                    /> 
                                                </Grid>
                                                {                                                    
                                                    <React.Fragment>
                                                    <Grid item style={{marginTop: 20}}>
                                                        <EventIcon fontSize="small"/>
                                                    </Grid>  
                                                    <Grid item xs={9} style={{marginTop: 20}}>
                                                        <Typography variant="subtitle2" align="left">Periodo cálculo CU</Typography>
                                                    </Grid>                                                                                                       
                                                    <Grid item xs={12} style={{marginTop: 10}}>
                                                        <DatePicker    
                                                            variant="dialog"    
                                                            inputVariant="standard"                                                                                 
                                                            okLabel={""}
                                                            cancelLabel={""}
                                                            openTo="month"
                                                            format="MMMM-yyyy"                                            
                                                            views={["year","month"]}                                           
                                                            value={new Date(props.periodDate).setMonth(props.periodDate.getMonth() + 1)}                                                                      
                                                            error={false}
                                                            InputProps={{              
                                                                        className: classes.formDefined,                              
                                                                        classes: {
                                                                            input: classes.formText,                                    
                                                                        }
                                                                    }}
                                                        
                                                            autoOk    
                                                            fullWidth
                                                            disabled                                                                              
                                                        /> 
                                                    </Grid>
                                                    </React.Fragment>
                                                }
                                               
                                                {/* <Grid item style={{marginTop: 20}}>
                                                    <AccountTreeIcon fontSize="small"/>
                                                </Grid>  
                                                <Grid item xs={9} style={{marginTop: 20}}>
                                                    <Typography variant="subtitle2" align="left">Metodología XM</Typography>
                                                </Grid>                                                                                                       
                                                <Grid item xs={12} style={{marginTop: 10}}>
                                                    <Grid container justify="center">
                                                        <Grid item style={{fontSize: 12}}>
                                                            .txf
                                                        </Grid>
                                                        <Grid item>
                                                            <Switch color="primary"/>
                                                        </Grid>
                                                        <Grid item style={{fontSize: 12}}>
                                                            .xml
                                                        </Grid>
                                                    </Grid>
                                                </Grid> */}
                                                <Grid item style={{marginTop: 20}}>
                                                    <ArchiveIcon fontSize="small"/>
                                                </Grid>  
                                                <Grid item xs={9} style={{marginTop: 20}}>
                                                    <Typography variant="subtitle2" align="left">Versión cálculo</Typography>
                                                </Grid>                                                                                                       
                                                <Grid item xs={12} style={{marginTop: 10}}>
                                                    <TextField
                                                        select
                                                        value={tariffCalcVersion}
                                                        onChange={(e) => {setTariffCalcVersion(e.target.value); 
                                                                        setTxfVersion(txfVersions[tariffCalcVersions.indexOf(e.target.value)]);
                                                                        setTariffsPublished(tariffsPublisheds[tariffCalcVersions.indexOf(e.target.value)]);
                                                                        setCalcType(calcTypes[tariffCalcVersions.indexOf(e.target.value)]);}}                                        
                                                        InputProps={{                                                        
                                                            classes: {
                                                                input: classes.formText
                                                            }
                                                        }}
                                                        className={classes.formRow} 
                                                    >
                                                        {tariffCalcVersions.map((option, idx) => (
                                                            <MenuItem key={idx} value={option} className={classes.itemText}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item style={{marginTop: 20}}>
                                                    <ArchiveIcon fontSize="small"/>
                                                </Grid>  
                                                <Grid item xs={9} style={{marginTop: 20}}>
                                                    <Typography variant="subtitle2" align="left">Versión archivos XM</Typography>
                                                </Grid>                                                                                                       
                                                <Grid item xs={12} style={{marginTop: 10}}>
                                                    <TextField                                                    
                                                        value={txfVersion}
                                                        //onChange={handleTxfVersionChange}                                        
                                                        InputProps={{
                                                            readOnly: true,
                                                            className: classes.formDefined,
                                                            classes: {
                                                                input: classes.formText
                                                            }
                                                        }}
                                                        className={classes.formRow} 
                                                        disabled
                                                    >                                                    
                                                    </TextField>
                                                </Grid>     
                                                <Grid item style={{marginTop: 20}}>
                                                    <ArchiveIcon fontSize="small"/>
                                                </Grid>  
                                                <Grid item xs={9} style={{marginTop: 20}}>
                                                    <Typography variant="subtitle2" align="left">Tipo de cálculo</Typography>
                                                </Grid>                                                                                                       
                                                <Grid item xs={12} style={{marginTop: 10}}>
                                                    <TextField                                                    
                                                        value={calcType}
                                                        //onChange={handleTxfVersionChange}                                        
                                                        InputProps={{
                                                            readOnly: true,
                                                            className: classes.formDefined,
                                                            classes: {
                                                                input: classes.formText
                                                            }
                                                        }}
                                                        className={classes.formRow} 
                                                        disabled
                                                    >                                                    
                                                    </TextField>
                                                </Grid>                                         
                                            </Grid> 
                                            :
                                            <Grid container justify="space-evenly" alignItems="center" style={{marginTop: 5, padding: 10}}>
                                                <Grid item>
                                                    <EventIcon fontSize="small"/>
                                                </Grid>  
                                                <Grid item xs={9}>
                                                    <Typography variant="subtitle2" align="left">Periodo de facturación</Typography>
                                                </Grid>                                                                                                       
                                                <Grid item xs={12} style={{marginTop: 10}}>
                                                    <DatePicker    
                                                        variant="dialog"    
                                                        inputVariant="standard"                                                                                 
                                                        okLabel={""}
                                                        cancelLabel={""}
                                                        openTo="month"
                                                        format="MMMM-yyyy"                                                                                                
                                                        views={["year","month"]}                                           
                                                        value={props.billingDate}   
                                                        minDate={new Date(2022,0,1)}                                        
                                                        maxDate={new Date(2025,11,1)}
                                                        onChange={(date) => { props.setBillingDate(date);}}
                                                        error={false}
                                                        InputProps={{                                                                                                        
                                                                    classes: {
                                                                        input: classes.formText,                                    
                                                                    }
                                                                }}                                                
                                                        autoOk                                                            
                                                        fullWidth                                                                              
                                                    /> 
                                                </Grid>
                                            </Grid>
                                        :
                                        <Grid container>
                                            <Grid item xs={6} className={classes.verticalText}>
                                                
                                            </Grid>
                                        </Grid>                               
                                    }   
                                </div>                                                                                                           
                            </div>
                        </Drawer>
                        :
                        null
                    }
                    
                    <main className={ openDrawer? 
                                    openSettings? 
                                                    classes.contentWithDrawerWithSettings
                                                    :  classes.contentWithDrawerWithoutSettings
                                    : openSettings? 
                                                    classes.contentWithoutDrawerWithSettings
                                                    : classes.contentWithoutDrawerWithoutSettings } >
                    {   isLoading ? 
                        <CircularProgress style={{marginTop: '10%'}}/>
                        :                 
                        <React.Fragment>                                                                                          
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/validacion-archivos"}>
                            <InputVerification username={props.username} periodDate={props.periodDate} opTariffPub={opTariffPub} 
                                agent={props.agent} market={props.market} tariffsPublished={tariffsPublished} 
                                tariffCalcVersion={tariffCalcVersion} reliqFinished={reliqFinished} txfVersion={txfVersion}
                                reliqTxfVersion={reliqTxfVersion} usedXmVersions={usedXmVersions} userType={userType} user={props.user}
                                />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/no-regulados-precio-bolsa"}>
                            <InputVerification username={props.username} periodDate={props.periodDate} opTariffPub={opTariffPub} 
                                agent={props.agent} market={props.market} tariffsPublished={tariffsPublished} 
                                tariffCalcVersion={'nr'} reliqFinished={reliqFinished} txfVersion={'.txr'}
                                reliqTxfVersion={reliqTxfVersion} usedXmVersions={usedXmVersions} userType={userType} user={props.user}
                                />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/validacion-parametros"}>
                            <ParameterVerification username={props.username} periodDate={props.periodDate} market={props.market}
                                opTariffPub={opTariffPub} marketDemand={props.marketDemand} tariffsPublished={tariffsPublished} tariffCalcVersion={tariffCalcVersion} 
                                strDist={strDist} calcType={calcType}  reliqFinished={reliqFinished} usedXmVersions={usedXmVersions} user={props.user}
                                userType={userType} role={props.role}
                             />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/mercados"}>
                            <Markets username={props.username} setMarkets={setMarkets} setMarketsDemand={setMarketsDemand}/>
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/contratos-compra"}>
                            <Contracts username={props.username} periodDate={props.periodDate} market={props.market} tariffCalcVersion={tariffCalcVersion}/>
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/contratos-venta"}>
                            <SellContracts username={props.username} periodDate={props.periodDate} market={props.market} tariffCalcVersion={tariffCalcVersion}/>
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/actores-financieros"}>
                            <FinancialActors username={props.username}/>
                        </Route>
                        
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/ofertas-no-regulados"}>
                            <NoRegOffers username={props.username} periodDate={props.periodDate} market={props.market} tariffCalcVersion={tariffCalcVersion}/>
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/consola-componentes"}>
                            <StateConsole username={props.username} periodDate={props.periodDate} market={props.market}
                                    txfVersion={txfVersion} tariffCalcVersion={tariffCalcVersion} tariffsPublished={tariffsPublished}
                                    calcType={calcType}
                                    tariffsPublisheds={tariffsPublisheds} markets={markets} setTxfVersion={setTxfVersion}
                                    setTxfVersions={setTxfVersions} setTariffCalcVersion={setTariffCalcVersion}
                                    setTariffCalcVersions={setTariffCalcVersions} setTariffsPublished={setTariffsPublished}
                                    setTariffsPublisheds={setTariffsPublisheds} setCalcType = {setCalcType} setCalcTypes = {setCalcTypes}
                                    definitiveVersion={definitiveVersion} setDefinitiveVersion={setDefinitiveVersion} reliqSelectedPeriod={reliqSelectedPeriod}                                   
                                    reliqTxfVersion={reliqTxfVersion} setReliqTxfVersion={setReliqTxfVersion} reliqLastTxfVersion={reliqLastTxfVersion}
                                    reliqFinished={reliqFinished} usedXmVersions={usedXmVersions} setReliqLastTxfVersion={setReliqLastTxfVersion}
                                    setReliqFinished={setReliqFinished} setUsedXmVersions={setUsedXmVersions} userType={userType} setOpTariffPub={setOpTariffPub}
                            />                           
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/opcion-tarifaria"}>
                            <OpTariff username={props.username} market={props.market} markets={markets} periodDate={props.periodDate} 
                                      tariffCalcVersion={tariffCalcVersion} calcType={calcType} marketOpTariff={marketOpTariff}
                                      setMarketOpTariff={setMarketOpTariff} setMarketsOpTariff={setMarketsOpTariff}
                                      setOpTariffPub={setOpTariffPub} 
                            />
                        </Route> 
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/arbol-tarifario"}>
                            <TariffTree username={props.username} market={props.market} periodDate={props.periodDate} 
                                tariffCalcVersion={tariffCalcVersion} calcType={calcType}
                                marketOpTariff={marketOpTariff}
                            />
                        </Route>   
                                              
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/reliquidaciones"}>
                            <Reliquidations username={props.username} market={props.market} periodDate={props.periodDate} 
                                tariffCalcVersion={tariffCalcVersion} calcType={calcType} reliqSelectedPeriod={reliqSelectedPeriod}
                                setReliqSelectedPeriod={setReliqSelectedPeriod} tariffsPublisheds={tariffsPublisheds}
                            />                                                   
                        </Route> 
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/publicar-tarifas"}>
                            <PublicationTree username={props.username} market={props.market} periodDate={props.periodDate} 
                                tariffCalcVersion={tariffCalcVersion} calcType={calcType}
                                marketOpTariff={marketOpTariff} tariffsPublished={tariffsPublished} markets={markets}
                                marketsOpTariff={marketsOpTariff}
                            />                        
                        </Route>           
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/alumbrado-publico"}>
                            <StreetLightTariff username={props.username} periodDate={props.periodDate} market={props.market} 
                                tariffCalcVersion={tariffCalcVersion} tariffsPublished={tariffsPublished}
                            />                        
                        </Route>              
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/reportes-sui"}>
                             <SUIReports username={props.username} periodDate={props.periodDate} 
                                 tariffCalcVersion={tariffCalcVersion}
                                 tariffsPublished={tariffsPublished} txfVersion={txfVersion}                               
                             />                             
                        </Route> 
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/balance-energetico"}>
                            <EnergyBalance username={props.username} periodDate={props.periodDate} tariffCalcVersion={tariffCalcVersion}/>
                        </Route>    
                        <Route path={"/web"+ props.username.toLowerCase() + "/principal/informe-comercializacion"}>
                            <CReport username={props.username} periodDate={props.periodDate} tariffCalcVersion={tariffCalcVersion}/>
                        </Route>  


                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/parametrizacion-ciclos"}>                                       
                            <CycleParam
                                username={props.username} periodDate={props.periodDate} tariffsPublished={tariffsPublished}
                            />
                        </Route>                        
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/parametrizacion-mensual"}>                                       
                            <MonthlyParam
                                username={props.username} periodDate={props.periodDate} tariffsPublished={tariffsPublished}
                                billingDate={props.billingDate}
                            />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/parametrizacion-anual"}>                                       
                            <AnualParam
                                username={props.username} billingDate={props.billingDate} tariffsPublished={tariffsPublished}
                            />
                        </Route>     
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/parametrizacion-cuentas-bancarias"}>                                       
                            <BankAccounts
                                username={props.username}
                            />
                        </Route>                           
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/parametrizacion-operadores-de-red"}>                                       
                            <NetOperators
                                username={props.username} markets={markets}
                            />
                        </Route>                           
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/parametrizacion-conceptos"}>                                       
                            <ConceptsControl
                                username={props.username} markets={markets}
                            />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/parametrizacion-alumbrado-publico"}>                                       
                            <StreetLight
                                username={props.username} markets={markets}
                            />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/parametrizacion-impuesto-seguridad"}>                                       
                            <SecurityCollect
                                username={props.username} markets={markets}
                            />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/control-principal-clientes"}>                                       
                            <ClientsControl
                                username={props.username} billingDate={props.billingDate} tariffsPublished={tariffsPublished}
                                markets={markets}
                            />
                        </Route>     
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/cargar-consumos-ciclo"}>                                       
                            <UploadConsumption
                                username={props.username} billingDate={props.billingDate} tariffsPublished={tariffsPublished}
                                tariffCalcVersion={tariffCalcVersion}
                            />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/informacion-consumos-cliente"}>                                       
                            <ConsumptionClientInfo
                                username={props.username} billingDate={props.billingDate} tariffsPublished={tariffsPublished}
                                tariffCalcVersion={tariffCalcVersion}
                            />
                        </Route>                           
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/liquidar-consumos"}>
                            <LiquidationOfConsumption username={props.username} billingDate={props.billingDate} market={props.market} tariffCalcVersion={tariffCalcVersion}/>
                        </Route>      
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/liquidar-alumbrado"}>
                            <LiquidationST username={props.username} billingDate={props.billingDate} market={props.market} tariffCalcVersion={tariffCalcVersion}/>
                        </Route>     
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/liquidar-impuesto-seguridad"}>
                            <LiquidationSecurity username={props.username} billingDate={props.billingDate} market={props.market} tariffCalcVersion={tariffCalcVersion}/>
                        </Route>                
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/cargar-otros-conceptos"}>                                       
                            <UploadConcepts
                                username={props.username} billingDate={props.billingDate} tariffsPublished={tariffsPublished}
                                tariffCalcVersion={tariffCalcVersion}
                            />
                        </Route>    
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/conceptos-por-cliente"}>                                       
                            <ConceptsClientInfo
                                username={props.username} billingDate={props.billingDate} tariffsPublished={tariffsPublished}
                                tariffCalcVersion={tariffCalcVersion}
                            />
                        </Route>     
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/generar-facturas"}>                                       
                            <BillGenerator username={props.username} billingDate={props.billingDate} market={props.market} tariffCalcVersion={tariffCalcVersion}/>
                        </Route>                                            
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/reportes-internos"}>                                       
                            <InternalReports username={props.username} billingDate={props.billingDate}/>
                        </Route>      
                        <Route path={"/web"+ props.username.toLowerCase() + "/facturador/reportes-sui"}>
                             <BillingSUIReports username={props.username} 
                                billingDate={props.billingDate}
                                periodDate={props.periodDate} 
                                tariffCalcVersion={tariffCalcVersion}
                                tariffsPublished={tariffsPublished} txfVersion={txfVersion}                               
                             />                             
                        </Route>                    
                        <Route path={"/web"+ props.username.toLowerCase() + "/tecnico-operativo/rev-tecnica-acta-oficio"}>
                            <ReviewCertification username={props.username} periodDate={props.periodDate} market={props.market}/>
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/tecnico-operativo/generar-orden"}>
                            <OrderAssignation username={props.username}/>
                        </Route>

                        {/* Autogeneradores */}
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/parametrizacion-precios-pactados"}>
                            <AgreedPrice username={props.username} periodDate={props.periodDate} market={props.market} tariffCalcVersion={tariffCalcVersion}/>
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/parametrizacion-ciclos"}>                                       
                            <AGCycles
                                username={props.username} periodDate={props.periodDate} tariffsPublished={tariffsPublished}
                            />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/usuarios"}>
                            <Clients username={props.username} billingDate={props.billingDate} tariffsPublished={tariffsPublished}
                                markets={markets}                                
                            />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/parametrizacion-conceptos"}>                                       
                            <LiqConcepts
                                username={props.username}
                            />
                        </Route>                        
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/parametrizacion-causas-no-lectura"}>                                       
                            <NoLectureCauses
                                username={props.username}
                            />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/control-archivos"}>                                       
                            <ConsumptionPanel
                                username={props.username} billingDate={props.billingDate} tariffsPublished={tariffsPublished}
                                tariffCalcVersion={tariffCalcVersion}
                            />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/consumos-cliente"}>                                       
                            <ClientConsumption
                                username={props.username} billingDate={props.billingDate} tariffsPublished={tariffsPublished}
                                tariffCalcVersion={tariffCalcVersion}
                            />
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/liquidacion-consumos"}>
                            <AGLiquidation username={props.username} billingDate={props.billingDate} market={props.market} tariffCalcVersion={tariffCalcVersion}/>
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/reporte-usuario"}>                        
                            <UserReport username={props.username} billingDate={props.billingDate}/>                        
                        </Route> 
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/exportar-liquidacion"}>                        
                            <ExportCSV username={props.username} billingDate={props.billingDate}/>                        
                        </Route>                        
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/informe-facturacion"}>                        
                            <InternalReportFile username={props.username} billingDate={props.billingDate}/>                        
                        </Route>              
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/tendencia-facturacion"}>                        
                            <InternalTrends username={props.username} billingDate={props.billingDate}/>                        
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/consultas/panel-principal"}>                        
                            <Principal username={props.username} billingDate={props.billingDate} user={props.user}/>                        
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/consultas/descargar-reporte"}>                        
                            <ClientReport username={props.username} billingDate={props.billingDate} user={props.user}/>                        
                        </Route>
                        <Route path={"/web"+ props.username.toLowerCase() + "/autogeneradores/consultas/estado-pago-excedentes"}>                        
                            <BalancePayment username={props.username} billingDate={props.billingDate} user={props.user}/>                        
                        </Route>
                        </React.Fragment>
                    }  
                    </main>
                </Grid>
            </Grid>                         
        </div>
    );
}
